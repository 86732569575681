import React from "react";
import Container from "react-bootstrap/Container";
import { Card, Alert } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

// import updatedFeatureImg from "../updated-feature.png";

export default function ReleaseNotes() {
  const metaData = React.useContext(AppMetaDataContext);

  const [searchParams] = useSearchParams();
  const updated = searchParams.get("updated");

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <Helmet>
        <title>Updates - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          <h1 className="display-4 mb-5">Updates</h1>
        </div>

        <div style={{ maxWidth: "700px" }}>
          {updated ? (
            <Alert variant="success">
              Your {metaData.appName} extension has been updated to the latest
              version!
            </Alert>
          ) : (
            <></>
          )}

          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Prompt library sync released</h3>
                <h6>12 July 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now sync your prompts across browsers. This feature is only available after registration.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
        </div>
      </div>
    </Container>
  );
}
