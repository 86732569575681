import React from "react";
import { Alert, Button } from "react-bootstrap";

import AppMetaDataContext from "../context/AppMetaDataContext";


function ExtensionAd() {
  const metaData = React.useContext(AppMetaDataContext);

  return (
    <Alert variant="secondary" className="mb-5">
          <Alert.Heading>
            Chrome browser extension
          </Alert.Heading>
          <div>
            Use the browser extension to:
            <ul>
              <li>
                Save prompts and re-use them
              </li>
              <li>
                Sync prompts across your browsers (devices)
              </li>
            </ul>
          </div>
          <p className="mb-0">
            The Chrome browser extension works also with Microsoft Edge, Brave, and Opera.
          </p>
          <hr />

          <div className="d-flex justify-content-start">
            <Button
              variant="dark"
              href="https://chromewebstore.google.com/detail/ai-prompt-saver/ipplfghnambhfflimmpbicbjhfgpfeoo"
              target="_blank"
            >
              Get the Chrome extension
            </Button>
          </div>
        </Alert>
  );
}

export default ExtensionAd;
