import React, { useContext } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BoxArrowInRight } from "react-bootstrap-icons";
// this is needed to make Bootstrap links react the same way as <Link> would in React Router
import { LinkContainer } from "react-router-bootstrap";
import AuthContext from "../context/AuthContext";
import AppMetaDataContext from "../context/AppMetaDataContext";
import logo from "../logo192.png";
import "./Navigation.css";

function Navigation(props) {
  // console.log("CONNNN NAV: ", this.context);
  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);
  console.log("Navigation got the context: ", context);

  // let planBadge;

  // switch (context.plan) {
  //   case "Free plan":
  //     planBadge = (
  //       <Badge bg="danger" text="dark">
  //         {context.plan}
  //       </Badge>
  //     );
  //     break;
  //   case "Premium plan":
  //     planBadge = (
  //       <Badge bg="dark" text="light">
  //         {context.plan}
  //       </Badge>
  //     );
  //     break;
  //   default:
  //     break;
  // }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        sticky="top"
        className="mb-5"
        style={{ backgroundColor: "lightblue", fontWeight: 500 }}
      >
        <Container>
          <LinkContainer
            to="/"
            className={context.user_id && "d-none d-sm-block"}
          >
            <Navbar.Brand href="/">
              {" "}
              <img
                src={logo}
                height="30"
                className="d-inline-block align-top"
                alt={metaData.appName + " logo"}
              />
            </Navbar.Brand>
          </LinkContainer>
          <LinkContainer to="/" className="d-none d-lg-block">
            <Navbar.Brand>{metaData.appName} </Navbar.Brand>
          </LinkContainer>

          {/* Nav for larger screens */}
          <Nav className="me-auto d-flex flex-row">
            {!context.user_id && (
              <LinkContainer to="/">
                <Nav.Link className="me-2">Home</Nav.Link>
              </LinkContainer>
            )}
            {context.user_id && (
              <LinkContainer to="/library">
                <Nav.Link className="me-2">Library</Nav.Link>
              </LinkContainer>
            )}
            {/* {!context.user_id && (
              <LinkContainer to="/pricing" className="me-2">
                <Nav.Link href="/pricing">Pricing</Nav.Link>
              </LinkContainer>
            )} */}
            {/* {context.user_id && (
              <LinkContainer to="/pricing" className="me-2 d-none d-md-block">
                <Nav.Link href="/pricing">Pricing</Nav.Link>
              </LinkContainer>
            )} */}
            {/* Contact link for larger screens */}
            {!context.user_id && (
              <LinkContainer to="/contact" className="me-2 d-none d-md-block">
                <Nav.Link>Contact</Nav.Link>
              </LinkContainer>
            )}
          </Nav>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="ms-3"
          />
          <Navbar.Collapse id="responsive-navbar-nav" style={{ flexGrow: 0 }}>
            <Nav className="ms-auto">
              {context.user_id ? (
                <>
                  {/* <Nav>
                    <LinkContainer to="/pricing">
                      <Nav.Link href="/pricing">{planBadge}</Nav.Link>
                    </LinkContainer>
                  </Nav> */}
                  <Nav>
                    {/* BUG: on desktop the dropdown overflows to right */}
                    <NavDropdown title="Settings">
                      {/* check if the user email contains a string */}
                      {context.email.includes("atjackiejohns") && (
                        <LinkContainer to="/admin">
                          <NavDropdown.Item href="/admin">
                            Admin &#128524;
                          </NavDropdown.Item>
                        </LinkContainer>
                      )}
                      <LinkContainer to="/account">
                        <NavDropdown.Item href="/account">
                          Account
                        </NavDropdown.Item>
                      </LinkContainer>
                      {/* <LinkContainer to="/pricing">
                        <NavDropdown.Item href="/pricing">
                          Change plan
                        </NavDropdown.Item>
                      </LinkContainer> */}
                      <NavDropdown.Divider className="d-none d-lg-block" />
                      <NavDropdown.Item
                        href="https://chromewebstore.google.com/detail/ai-prompt-saver/ipplfghnambhfflimmpbicbjhfgpfeoo"
                        target="_blank"
                        className="d-none d-lg-block"
                      >
                        Browser extension
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <LinkContainer to="/logout">
                        <NavDropdown.Item>
                          Log out <BoxArrowInRight />
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  </Nav>
                </>
              ) : (
                <>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/register">
                    <Nav.Link>Sign up</Nav.Link>
                  </LinkContainer>
                </>
              )}
              {/* Contact link for smaller screens */}
              {/* <LinkContainer to="/https" className="d-md-none">
                <Nav.Link>Pricing</Nav.Link>
              </LinkContainer> */}
              <a
                href="https://chromewebstore.google.com/detail/ai-prompt-saver/ipplfghnambhfflimmpbicbjhfgpfeoo"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link d-lg-none"
              >
                Browser extension
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
