import React from "react";
import { Helmet } from "react-helmet-async";
import { Image, Row, Col } from "react-bootstrap";
import { Linkedin, Twitter, Medium } from "react-bootstrap-icons";
import meelisImage from "../meelis.jpg";
import logo from "../logo192.png";
import AppMetaDataContext from "../context/AppMetaDataContext";

export default function Contact() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>Contact - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div
        className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center"
        style={{ maxWidth: "700px", marginBottom: "10%" }}
      >
        <h1 className="display-4">Get in touch</h1>

        <Row>
          <Col style={{ marginTop: "15%" }}>
            <h4>Support</h4>
            <br />
            <div>
              <a
                href="https://tally.so/r/w52vAM"
                target="_blank"
                style={{ color: "black" }}
              >
                Feedback & bugs
              </a>
            </div>
            <br />
            <br />
          </Col>
          <Col style={{ marginTop: "15%" }}>
            <h4>The Company</h4>
            <br />
            <img
              src={logo}
              alt="Logo"
              className="img-fluid"
              style={{ maxWidth: "100px" }}
            />
            <br />
            <br />
            <strong>Mink OÜ</strong>
            <br />
            <a
              href={
                "mailto:info@" +
                metaData.domain +
                "?subject=Feedback from " +
                metaData.appName +
                " contact page"
              }
            >
              info@{metaData.domain}
            </a>
            <br />
            Company reg number: 11381784
            <br />
            <br />
            <h6>Juridical address</h6>
            Mink OÜ
            <br />
            Õle tn 24-5
            <br />
            Tallinn, Estonia
            <br />
            10319
          </Col>
          <Col style={{ marginTop: "15%" }}>
            <h4>The People</h4>
            <br />
            <Image
              src={meelisImage}
              alt="Meelis Ojasild"
              roundedCircle={true}
              style={{ maxWidth: "100px" }}
            />
            <br />
            <br />
            <strong>Meelis Ojasild</strong>
            <br />
            Founder of {metaData.appName}
            <br />
            <br />
            <a href="https://www.linkedin.com/in/meelisojasild/">
              <Linkedin style={{ fontSize: "1.5rem" }} />
            </a>{" "}
            <a href="https://twitter.com/meelis_o">
              <Twitter style={{ fontSize: "1.5rem" }} />
            </a>{" "}
            <a href="https://medium.com/@meelis-ojasild">
              <Medium style={{ fontSize: "1.5rem" }} />
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
}
