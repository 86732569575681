import React, { useContext, useEffect } from "react";
import Navigation from "./Components/Navigation";
import { Route, Routes, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./pages/Home";
import Library from "./pages/Library";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Account from "./pages/Account";
import Privacy from "./pages/Privacy";
import ReleaseNotes from "./pages/ReleaseNotes";
import TermsOfService from "./pages/TermsOfService";
import NotFound from "./pages/NotFound";
import Footer from "./Components/Footer";

import ScrollToTop from "./utils/ScrollToTop";

// could be rewritten and simplified to use a Hook instead
// https://www.youtube.com/watch?v=ZBZ6BqoUDsU&ab_channel=yoursTRULY
import AuthContext from "./context/AuthContext";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function App() {
  const context = useContext(AuthContext);
  console.log("CONTEXT VARIABLES AND METHODS: ", context);

  // send pageview to Google Analytics only when location (path) changes
  let location = useLocation();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-X92FWBEHN4");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    context.getAuthInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (context.has_been_verified === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navigation />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={context.user_id ? <Navigate to="/library" /> : <Home />}
        />
        <Route path="/home" element={<Home />} />
        {context.user_id && <Route path="/library" element={<Library />} />}

        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/account/change-password" element={<ChangePassword />} />
        {context.user_id && <Route path="/account" element={<Account />} />}
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/release-notes" element={<ReleaseNotes />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
