import React from "react";
import {
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import mainScreenshot from "../main-screenshot-1.png";

// import AppMetaDataContext from "../context/AppMetaDataContext";

function MainCTA( {language} ) {
  // const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
        <Row style={{ margin: "0% 3%", marginBottom: "5%" }}>
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <h4 className="display-5 mb-5">
              AI Prompt Saver
            </h4>
            <h4>
              Save and re-use prompts across OpenAI, Gemini, and other AI tools.
            </h4>
            <br />
              <Button size="lg" variant="primary" className="mx-2" href="https://chromewebstore.google.com/detail/ai-prompt-saver/ipplfghnambhfflimmpbicbjhfgpfeoo">
                Download the extension
              </Button>
            <LinkContainer to="/register">
              <Button size="lg" variant="success" className="mx-2">
                Sign up
              </Button>
            </LinkContainer>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 2 }}
            style={{ paddingBottom: "5%" }}
          >
            <img src={mainScreenshot} alt="Logo" className="img-fluid" />
          </Col>
        </Row>
        <br />
        <br />
        <hr />
      </div>
    );
}

export default MainCTA;
