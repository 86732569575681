import React, { useRef, useContext, useState, useEffect } from "react";
import { Form, Button, Card, Container, Alert, Spinner } from "react-bootstrap";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);

  const [searchParams] = useSearchParams();
  const signupMessage = searchParams.get("signup");

  const [redirectionMessage, setRedirectionMessage] = useState(undefined);

  // if the user signed up, sent an event to Google Analytics
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-X92FWBEHN4");
      window.GA_INITIALIZED = true;
    }
    if (signupMessage) {
      ReactGA.event({
        category: "setup",
        action: "sign_up",
        // label: "lifecycle", // optional
        // value: 99, // optional, must be a number
        // nonInteraction: false, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
      });
    }
  }, [signupMessage]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    console.log("Login form submitted with email: ", emailRef.current.value);

    const body = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };
    console.log("Login API query with email: ", body.email);
    axios
      .post("/api/user/login", body)
      .then((res) => {
        console.log("Login API response: ", res.data);
        // needs to be checked, in order to have a re-render based on context
        context.getAuthInfo();
        setIsLoading(false);
        setRedirectionMessage(
          "Login successful. Redirecting to your library..."
        );
      })
      .catch((err) => {
        console.log("Login failed with message: ", err.response.data);
        setErrorMessage(err.response.data);
        setIsLoading(false);
      });
  };

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <Helmet>
        <title>Log in - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div className="w-100" style={{ maxWidth: "400px" }}>
        {signupMessage && (
          <Alert variant="success">
            Registration successful. Please log in.
          </Alert>
        )}
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Log in</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  ref={emailRef}
                  className="w-20"
                />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" required ref={passwordRef} />
              </Form.Group>
              <Button type="submit" className="w-100 mt-4" disabled={isLoading}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className={isLoading ? "me-2" : "d-none"}
                />
                <span>Log in</span>
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          {redirectionMessage && (
            <Alert variant="success">{redirectionMessage}</Alert>
          )}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {context.user_id ? (
            <Navigate replace to="/library" />
          ) : (
            <div>
              <div>
                Not registered yet? <Link to="/register">Sign up</Link>
              </div>
              <div className="mt-2">
                <Link to="/forgot-password">Forgot your password?</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
