import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>Privacy policy - {metaData.appName}</title>
        <meta
          name="description"
          key="description"
          content={metaData.metaDesc}
        />
        <meta property="og:title" key="og:title" content={metaData.metaTitle} />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDesc}
        />
      </Helmet>

      <Container style={{ padding: "3% 3%" }}>
        <h1 className="text-center" style={{ marginBottom: "7%" }}>
          Privacy Policy
        </h1>

        <p>
          <ul>
            <li>
              <strong>
                Your prompts are private and will not be shared with any third parties.
              </strong>
            </li>
            <li>
              We don't collect any personally identifiable information such as
              your name, email address, or phone number (except when you
              exclusively provide it to us, for example, during signup).
            </li>
            <li>
              We may collect information that is essential for providing the
              service (your browser ID, for example).
            </li>
            <li>
              We collect usage statistics to improve the service and fix bugs.
            </li>
            <li>
              We don't share any information we collect with third parties.
            </li>
            <li>The extension is disabled in the browser's incognite mode.</li>
          </ul>
        </p>

        <br />
        <br />
        <br />
        <br />
      </Container>
    </>
  );
}

export default Home;
