import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

import socialImage from "../screenshot-front-2.jpg";

import MainCTA from "../Components/MainCTA";
import HowItWorks from "../Components/HowItWorks";
import Benefits from "../Components/Benefits";
import SignUpBlock from "../Components/SignUpBlock";
import BrowserExtension from "../Components/BrowserExtension";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>{metaData.metaTitle}</title>
        <meta
          name="description"
          key="description"
          content={metaData.metaDesc}
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "3% 3%" }}>
        <MainCTA />
        <HowItWorks />
        {/* <Benefits />
        <BrowserExtension /> */}
        {/* <FounderQuote /> */}
        <SignUpBlock />
      </Container>
    </>
  );
}

export default Home;
