import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Alert,
  Button,
  ListGroup,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSearchParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

export default function Account() {
  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);

  // check if a callback arrived from Stripe's checkout - not sure if needed
  // right now creates an infinite loop - doesn't stop checking
  // const [searchParams] = useSearchParams();
  // const subscribedToNewPlan = searchParams.get("subscription")
  // if (subscribedToNewPlan) context.getAuthInfo();

  const [searchParams] = useSearchParams();
  const stripeSuccess = searchParams.get("subscription");

  const setNewsletterStatus = (e) => {
    console.log("Event: ", e.target.checked);
    const request = {
      value: e.target.checked,
    };
    axios
      .put("/api/user/update-newsletter-setting", request)
      .then((response) => {
        console.log("RESPONSE: ", response.data);
      }).catch((error) => {
        console.error("Error updating newsletter setting: ", error);
      });
  };

  const updateNotificationSetting = (settingName, e) => {
    console.log(`Updating ${settingName}: `, e.target.checked);
    const request = {
      settingName,
      value: e.target.checked,
    };
    axios
      .put("/api/user/update-notification-setting", request)
      .then((response) => {
        console.log("RESPONSE: ", response.data);
      })
      .catch((error) => {
        console.error("Error updating notification setting: ", error);
      });
  };

  // const setNotificationsStatus = (e) => {
  //   console.log("Event: ", e.target.checked);
  //   const request = {
  //     value: e.target.checked,
  //   };
  //   axios
  //     .put("/api/user/update-notifications-setting", request)
  //     .then((response) => {
  //       console.log("RESPONSE: ", response.data);
  //     });
  // };

  // change buttons for updating plans based on current plan
  let planButtons;
  switch (context.plan) {
    case "Premium plan":
      planButtons = (
        <>
          {/* <Button
            href={process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL}
            className="mb-2 mb-sm-0 me-sm-2"
          >
            Change plan
          </Button> */}
          <Button
            href={process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL}
            className="mb-2 mb-sm-0 me-sm-2"
          >
            Billing portal
          </Button>
          <Button
            href={process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL}
            className="mb-2 mb-sm-0 me-sm-2"
          >
            Change plan
          </Button>
        </>
      );
      break;
    default:
      planButtons = (
        <LinkContainer to="/pricing">
          <Button variant="success">Go premium</Button>
        </LinkContainer>
      );
      break;
  }

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <Helmet>
        <title>Account Settings - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <h1 className="display-4 mb-5">Account Settings</h1>

      <br />
      <br />

      <div className="w-100" style={{ maxWidth: "700px" }}>
        {stripeSuccess ? (
          <Alert variant="success" className="mb-5">
            <strong>Plan change successful.</strong> Please allow a few minutes
            for updating if you don't see your new plan show up here
            immediately.
          </Alert>
        ) : (
          <></>
        )}
        
        <h4>Personal</h4>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Your email</div>
              {context.email}
            </div>
          </ListGroup.Item>
        </ListGroup>

        <h4 className="mt-5">Notifications</h4>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto fw-bold">
              Product updates newsletter
            </div>
            <div>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  defaultChecked={context.newsletter}
                  onClick={setNewsletterStatus}
                />
              </Form>
            </div>
          </ListGroup.Item>
        </ListGroup>

        {/* <h4 className="mt-5">Plans & billing</h4>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex flex-column align-items-start align-items-sm-center flex-sm-row justify-content-between"
          >
            <div className="ms-2 me-auto mb-2">
              <div className="fw-bold">Current plan</div>
              {context.plan}
            </div>
            <div className="d-flex flex-column align-items-start align-items-sm-center flex-sm-row">
              <LinkContainer to="/pricing" className="mb-2 mb-sm-0 me-sm-2">
                <Button>See all plans</Button>
              </LinkContainer>
              {planButtons}
            </div>
          </ListGroup.Item>
        </ListGroup> */}

        <h4 className="mt-5">Security</h4>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Password</div>
            </div>
            <LinkContainer to="/account/change-password">
              <Button href="/account/change-password">Change password</Button>
            </LinkContainer>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <br />
      <br />
    </Container>
  );
}
