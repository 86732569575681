import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";
import ReactGA from "react-ga4";

export default function Register() {
  const metaData = React.useContext(AppMetaDataContext);

  const [errorMessage, setErrorMessage] = useState(undefined);
  // after backend registration success use this state to redirect to /login form
  const [shouldRedirect, setShouldRedirect] = useState(false);
  // newsletter checkbox
  const [shouldCheck, setShouldCheck] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();
  const newsletterRef = useRef();

  // useEffect for newsletter checkbox
  useEffect(() => {
    axios
      .get("/api/check-ip-for-newsletter")
      .then((response) => {
        // Assuming the endpoint returns true or false
        setShouldCheck(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error with checking the IP for newsletter!",
          error
        );
      });
  }, []);

  const handleSubmit = async (data) => {
    data.preventDefault();

    console.log("Register form submitted with email: ", emailRef.current.value);

    const body = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      newsletter: newsletterRef.current.checked,
    };
    console.log("Register API query body.email: ", body.email);
    // send API request to register back-end
    axios
      .post("/api/user/register", body)
      .then((res) => {
        console.log("Register API response: ", res.data);
        setShouldRedirect(true);
        // Sending a track event to Google Analytics
        ReactGA.event({
          category: "setup",
          action: "signed_up",
          label: "signed_up",
        });
      })
      .catch((err) => {
        console.log("Registration failed with message: ", err.response.data);
        setErrorMessage(err.response.data);
      });
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minheight: "100vh" }}
    >
      <Helmet>
        <title>Register - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Try it for free</h2>
            <Card.Text className="text-center">
              No credit card required.
            </Card.Text>
            <Form onSubmit={handleSubmit}>

              <Form.Group id="email" className="my-2">
                <Form.Label>Your email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  ref={emailRef}
                  className="w-20"
                />
              </Form.Group>
              <Form.Group id="password" className="my-2">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" required ref={passwordRef} />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={shouldCheck}
                  label="Let me know via email about new features"
                  ref={newsletterRef}
                  onChange={() => setShouldCheck(!shouldCheck)}
                />
              </Form.Group>
              <Button type="submit" className="w-100 mt-4">
                Sign up
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          Already have an account? <Link to="/login">Log in</Link>
        </div>
        {shouldRedirect && (
          <div>
            <Navigate to="/login?signup=true" />
          </div>
        )}
      </div>
    </Container>
  );
}
