import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

export default function NotFound() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>Not Found - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>
      <Container className="d-flex align-items-center justify-content-center">
        <div>
          Page not found. Try refreshing the page or logging in if you think you
          saw this page as an accident.
        </div>
      </Container>
    </>
  );
}
