import React from "react";
import { Card, CardGroup, Badge, Image } from "react-bootstrap";

import flashcards from "../flashcards-big-update.png";

// import AppMetaDataContext from "../context/AppMetaDataContext";

function HowItWorks() {
  // const metaData = React.useContext(AppMetaDataContext);

  return (
    <>
      <div>
        <h1
          className="text-center"
          style={{ marginBottom: "7%", marginTop: "10%" }}
        >
          Features
        </h1>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                Save prompts
              </Card.Title>
              <Card.Text>
                Save prompts by adding them manually or by selecting the text and right clicking it.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                Re-use prompts
              </Card.Title>
              <Card.Text>
                Insert the saved prompts either by copying them to the clipboard or by right clicking on any text field and selecting the prompt.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                Sync prompts across your browsers{" "}
                <sup>
                  <Badge bg="success" pill className="mt-2">
                    New!
                  </Badge>
                </sup>
              </Card.Title>
              <Card.Text>
                Sync your saved prompts across your browsers (devices). All you need is a free account.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        {/* <h1
          className="text-center"
          style={{ marginBottom: "3%", marginTop: "10%" }}
        >
          Level Up With Flashcards{" "}

        </h1>

        <div className="d-flex justify-content-center">
          <div style={{ maxWidth: "800px" }}>
            <Image
              src={flashcards}
              style={{ maxWidth: "100%", height: "auto" }}
              className="mb-3 mt-0 px-3"
              fluid
            />
          </div>
        </div>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>Practice the vocabulary with flashcards</Card.Title>
              <Card.Text>
                Keep practicing the vocabulary that you might not see on web
                pages you visit. Flashcards work on the principle of spaced
                repetition - you'll see the ones you get wrong more often. You
                can filter flashcards by Duolingo skills (such as "Family" and
                "Travel").
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>
                See the words in AI-generated sample sentences
              </Card.Title>
              <Card.Text>
                Flashcards contain AI-generated sample sentences to help you
                understand the context.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup> */}
      </div>
    </>
  );
}

export default HowItWorks;
