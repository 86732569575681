import React from "react";
import {
  Button,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

// import AppMetaDataContext from "../context/AppMetaDataContext";

function DownloadExtension( {language} ) {
  // const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
        <div className="text-center" style={{ margin: "0% 3%" }}>
          <h1 className="text-center" style={{ paddingTop: "15%" }}>
            Sign up for free
          </h1>
          <h4
            className="text-center"
            style={{ padding: "2% 0%", fontWeight: "normal" }}
          >
            Start re-using your prompts in under 30 seconds
          </h4>
          <LinkContainer to="/register">
            <Button size="lg" variant="success">
              Sign up now
            </Button>
          </LinkContainer>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    );
}

export default DownloadExtension;
