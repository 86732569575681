import React, { Component } from "react";

const AppMetaDataContext = React.createContext();

export class AppMetaDataProvider extends Component {
  state = {
    appName: "Napkin Developer",
    metaTitle:
      "Napkin Developer - prompt AI better",
    metaDesc:
      "Save prompts and sync them across browsers and devices with AI Prompt Saver.",
    metaTitleSocial:
      "Napkin Developer - prompt AI better",
    metaDescSocial:
      "Save prompts and sync them across browsers and devices with AI Prompt Saver.",
    domain: "napkindeveloper.net",
  };

  render() {
    const {
      appName,
      metaTitle,
      metaDesc,
      metaTitleSocial,
      metaDescSocial,
      domain,
    } = this.state;

    return (
      <AppMetaDataContext.Provider
        value={{
          appName,
          metaTitle,
          metaDesc,
          metaTitleSocial,
          metaDescSocial,
          domain,
        }}
      >
        {this.props.children}
      </AppMetaDataContext.Provider>
    );
  }
}

export default AppMetaDataContext;
